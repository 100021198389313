@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@1,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Patua+One&family=Ubuntu+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Langar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap");
.App {
  text-align: center;
}
body {
  background-color: #0c3823;
  color: beige;
}
* {
  scroll-behavior: smooth;
}
.header-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  width: 100%;
  height: 55vh;
}
.carousel-item {
  overflow: hidden;
  width: 100%;
}
.carousel-section {
  width: 193vh;
  padding-left: 75px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border-right: none;
  border-left: none;
}
.scholar {
  height: 270px;
  width: 500px;
}
.scholarimg {
  height: 270px;
  width: 700px;
}
.ayah {
  height: 250px;
  width: 100%;
}
.heading-section {
  color: beige;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.heading-name {
  font-size: 35px;
}
nav {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
ul .navbar-nav {
  padding-bottom: 0px;
}
li .nav-link {
  font-size: 2rem;
  color: #5c4033;
}
li .nav-link:hover {
  color: beige;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
Link .nav-link {
  font-size: 2rem;
  color: #5c4033;
  border-right: #5c4033 dotted;
}

Link .nav-link:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.navbar-color {
  background: url("../src/img/beigebg.jpeg");
}
.bg {
  background: url("../src/img/beigebg.jpeg");
}
.homeYoutube {
  height: 50px;
  width: 50px;
}

.heading-section h1 {
  color: beige;
  font-family: "Amiri";
}
.heading-name {
  font-size: 30px;
}
.heading-lead {
  padding-top: 0%;
  font-size: 20px;
}
.embed-section {
  margin-top: 25px;
}
.calendar-section {
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.mosque {
  margin-top: 25px;
  background-color: #0c3823;
}
.three-col-section {
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
}

.mixlr-section {
  height: 215px;
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.telegram-section {
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.latest-section {
  height: 215px;
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
  padding-top: 30px;
  padding-left: 30px;
}
.mixlr-img-section {
  height: 215px;
  background: url("../src/img/beigebg.jpeg");
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}
.calendar {
  height: 215px;
}
.mixlr {
  background-color: beige;
  height: 215px;
}
img .logo {
  height: 50px;
  width: 50px;
}

.display-head {
  margin-top: 25px;
  font-family: "Gloria Hallelujah", sans-serif;
  color: beige;
  font-size: 30px;
}
.display-title {
  font-family: "Fredoka One", sans-serif;
  color: beige;
  text-shadow: 2px 2px 4px #000000;
}

.displayimg {
  height: 215px;
  width: 1000px;
  border: 2px #5c4033;
}
.audio-display-head {
  margin-top: 25px;
  font-family: "Amiri", sans-serif;
  font-size: 40px;
}
.rounded-links {
  border: 9px solid #5c4033;
  height: 9rem;
  width: 9rem;
  background-color: beige;
  background-image: url(../src/img/beigebg.jpeg);
}

a .rounded-headname {
  font-family: "Righteous", sans-serif;
  color: beige;
  font-size: 1.7rem;
}
a .rounded-headname:hover {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.btn {
  width: auto;
}
.card-header {
  font-family: "Tajawal", sans-serif;
  font-size: 30px;
}
.card-header2 {
  font-size: 40px;
}
.card {
  color: #5c4033;
  background-image: url(../src/img/beigebg.jpeg);
}
#menu {
  position: fixed;
  right: 2rem;
  top: 80%;
  width: 8em;
}

.caption {
  font-size: 30px;
}
.iframe {
  width: 1200px;
}

.aboutheader {
  font-family: "Langar", sans-serif;
  text-transform: uppercase;
  color: beige;
  text-shadow: beige;
}
.about {
  font-size: 20px;
  font-family: "Fredoka", sans-serif;
}

.orderlist {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #5c4033;
  text-decoration: underline;
}

.responsiveArchives {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsiveArchives iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}
.responsiveCal {
  position: relative;
  padding-bottom: 40%;
  height: 100%;
  overflow: hidden;
}
.responsiveCal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mixlricon {
  height: 40px;
  width: 40px;
}
.mixlrtext {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: #5c4033;
  padding-top: 10px;
}
.icon-bar {
  display: none;
}
.phoneicon {
  height: 40px;
  width: 40px;
  display: none;
}

.subscribe-circle {
  border: 9px solid #5c4033;
  height: 10rem;
  width: 10rem;
  background-color: beige;
  background-image: url(../src/img/beigebg.jpeg);
}
.subscribe-links {
  border: 9px solid #5c4033;
  height: 10rem;
  width: 10rem;
  padding-top: 50px;
  background-color: beige;
  background-image: url(../src/img/beigebg.jpeg);
}
.subscribeicon {
  padding-top: 20px;
  padding-left: 15px;
  font-size: 80px;
}
.subscribemixlr {
  padding-bottom: 10px;
  padding-left: 5px;
  height: 10rem;
  width: 10rem;
}
.jumbotron {
  background-image: url(../src/img/beigebg.jpeg);
  width: 500px;
  height: 350px;
  text-align: center;
  color: #5c4033;
}
.jumbotron .display-4 {
  font-family: "Potta One", sans-serif;
}
.accordion-flush {
  background-color: beige;
}
h2 .accordion-header {
  color: #5c4033;
}
.accordion-body {
  background-image: url(../src/img/beigebg.jpeg);
}

.accordion-button {
  background-color: beige;
  color: #5c4033;
  font-size: 2rem;
  font-family: "Patua One", sans-serif;
}

.accordion-collapse .collapse {
  background-color: beige;
}
.accordion-flush {
  background-color: beige;
}
.card-header2 {
  background-color: beige;
  color: #5c4033;
}

table.table-bordered {
  border: 5px solid #5c4033;
}

.tableBg {
  background: url(../src/img/beigebg.jpeg);
}
.wagroup {
  height: 120px;
  width: 120px;
}
.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
}
.tableButton {
  height: 65px;
  background-color: #0c3823;
  color: beige;
  font-size: 15px;
  font-family: "Righteous";
}
.social-image {
  height: 200px;
  width: 290px;
}
.Social-link-btn {
  background-color: #0c3823;
  color: white;
  border-radius: 6px;
}
.icon-shadow {
  text-shadow: 5px 5px 20px beige;
}
.mobileBanner {
  display: none;
}
.white-shadows {
  -moz-box-shadow: 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #fff;
  box-shadow: 0px 0px 5px #fff;
}
.text-red {
  color: #EE4B2B;
}
.bg-brown {
  background-color: #5c4033;
}
.text-gold {
  color: #FFDF00;
}
.bg-green {
  background-color: #0c3823;
}
.text-xs {
 font-size: smaller;
}
.popular-img-height {
height: 150px;
}
.u {
  text-decoration: underline;
}
.text-beige {
  color: beige;
}
.linktree-bg {
  background-color: white;
  color: #5c4033;
  padding-right: 150px;
  padding-left: 150px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 60px;
  font-size: 20px;
  font-family: "Potta One";
}

.linktree-head {
  font-size: 30px;
}
@media (min-width: 1280.99px) {
  .mobileBanner {
    display: none;
  }
}
@media (min-width: 1024.99px){
.calendar-design {
  height: 508px;
}
}

@media (max-width: 991.98px) {
  .latest-section {
    height: 215px;
  }
  .phoneicon {
    display: none;
  }
  .ayah {
    width: 100%;
  }
  .scholar {
    width: 650px;
  }
  .tableButton {
    height: 70px;
  }
  .social-image {
    height: 150px;
    width: 240px;
  }
  .mobileBanner {
    display: none;
  }
}

@media (min-width: 768px) {
  .heading-name {
    font-size: 25px;
  }
  .heading-lead {
    padding-top: 0%;
    font-size: 15px;
  }
  li .nav-link {
    font-size: 1.6rem;
    border-right: none;
  }
  .caption {
    font-size: 40px;
  }
  .responsiveCal {
    position: relative;
    padding-bottom: 100%;
  }
  .subscribe-circle {
    border: 5px solid #5c4033;
    height: 6rem;
    width: 6rem;
  }

  .responsiveCal iframe {
    position: absolute;
  }
  .mixlricon {
    height: 25px;
    width: 25px;
  }
  .mixlrtext {
    font-size: 20px;
  }
  .mausamiyatmargin {
    margin-right: 0;
    margin-left: 0;
  }
  .paddingVideo {
    padding-left: 0%;
  }

  .tableBg {
    margin-right: 12px;
  }
  .tableButton {
    height: 70px;
    width: 150px;
  }
  .carousel-section {
    width: 100%;
    padding-left: 0px;
  }
  .img-fld {
    height: 70%;
    opacity: 75%;
  }
  .scholar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-image {
    height: 150px;
    width: 240px;
  }
  .mobileBanner {
    display: none;
  }
  .calendar-design {
    height: 500px;
  }
  .linktree-head {
    font-size: 27px;
  }
  .linktree-bg {

    padding-right: 120px;
    padding-left: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .jumbotron {
    width: 300px;
    height: 350px;
  }
  .card-header {
    font-size: 35px;
  }
  .heading-name {
    font-size: 20px;
  }
  .heading-lead {
    padding-top: 0%;
    font-size: 15px;
  }
  li .nav-link {
    font-size: 1rem;
    border-right: none;
  }
  #menu {
    display: none;
  }
  .tableButton {
    height: 50px;
    width: 84px;
    font-size: 11px;
  }
  .card {
    font-size: 15px;
    margin: 3px;
  }
  .figure-caption {
    color: beige;
    font-size: 20px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  .caption {
    font-size: 10px;
  }
  .responsiveCal {
    position: relative;
    padding-bottom: 100%;
  }
  .aboutheader {
    font-size: 30px;
  }
  .about {
    font-size: 16px;
    margin: 5px;
  }

  .responsiveCal iframe {
    position: absolute;
  }
  .boardcard {
    font-size: 15px;
  }
  .mixlricon {
    height: 30px;
    width: 30px;
  }

  .subscribe-circle {
    height: 4px;
    width: 4px;
  }
  .phoneicon {
    height: 40px;
    width: 40px;
  }
  .mausamiyatmargin {
    margin-right: 0;
    margin-left: 0;
  }
  .paddingVideo {
    padding-left: 0%;
  }
  .ayah {
    display: none;
  }
  a .rounded-headname {
    font-size: 18px;
  }
  .img-fld {
    height: 30vh;
    width: 100%;
  }
  .scholar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 90px;
  }
  .carousel-section {
    width: 100%;
    padding-left: 0px;
  }
  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-control-next-icon {
    display: none;
  }
  .mobile-first {
    vertical-align: center;
    left: 50%;
    right: 50%;
  }
  .social-image {
    display: none;
  }
  .mobileBanner {
    display: block;
  }
  .mobileBanner img {
    width: 100%;
  }
  .calendar-design {
    height: 500px;
  }
  .linktree-head {
    font-size: 15px;
  }
  .linktree-bg {
font-size: 15px;
    padding-right: 70px;
    padding-left: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
